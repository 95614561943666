@use "index";
@use "sass:color";

form {
  input:not([type=button]):not([type=submit]):not([type=checkbox]), select {
    border: none;
    padding: 15px;
    width: 100%;
    background-color: index.$textInputColor;
    border-radius: 5px;
    font-size: 16px;
    &::placeholder {
      color: index.$textInputText;
    }
    &[disabled] {
      background-color: transparent;
    }
    &[type=file] {
      background-color: transparent;
      cursor: pointer;
    }
  }
  input[type=date] {
    flex-direction: row;
  }
  .picker {
    position: relative;
    select {
      cursor: pointer;
      -webkit-appearance:none;
      -moz-appearance:none;
      appearance:none;
      position: relative;
      padding-right: 35px;
    }
    .caret {
      pointer-events: none;
      display: block;
      position: absolute;
      cursor: pointer;
      right: 1rem;
      top: 50%;
      margin-top: -1px;
      width: 0;
      height: 0;
      border-top: 5px solid #000;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }
  .time-field > div {
    flex-direction: row;
    align-items: stretch;
    & > * {
      margin: 0 5px;
    }
    label > span {
      display: none;
    }
    p {
      justify-content: center;
      font-weight: bold;
      font-size: 24px;
    }
    select {
      padding-right: 40px;
      width: fit-content;
    }
  }
}

.simple-form {
  background-color: index.$backgroundColor;
  padding: 40px;
  label, .align-top {
    flex-direction: row;
    span {
      flex: 1;
      flex-direction: row;
      align-items: center;
      font-weight: bold;
      margin-right: 15px;
      .asterisk {
        color: index.$errorText;
      }
    }
    .input-wrapper {
      flex: 2;
    }
    .checkbox {
      margin: 15px 0;
    }
  }
  .align-top span {
    align-items: flex-start;
    padding: 20px 0;
  }

  .week-array input {
    margin-bottom: 10px;
  }

  .footer {
    flex-direction: row;
    justify-content: space-between;
    .buttons {
      flex-direction: row;
      justify-content: flex-end;
      input {
        padding: 12px;
        margin-left: 10px;
        &.cancel {
          background-color: transparent;
          border: 1px index.$checkboxText solid !important;
          color: index.$checkboxText !important;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.pop-up-form-background {
  transition: all 0.2s ease;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #0008;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  &.closed {
    opacity: 0;
    pointer-events: none;
    form {
      pointer-events: none;
      transform: scale(0.9);
    }
  }
  form {
    transition: transform 0.2s ease;
    border-radius: 20px;
    pointer-events: auto;
    box-shadow: 0 0 10px 0 #0000003f;
    overflow: auto;
  }
}

.edit-row-form {
  width: 60vw;
  max-width: 800px;
  max-height: 90vh;
}

.checkbox-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 156px;
    .checkbox {
      margin-right: 10px;
      flex: 0;
    }
  }
}

.schedule-field {
  margin: 0 !important;
  table {
    box-shadow: none;
  }
  tr {
    background-color: transparent !important;
    align-items: flex-start;
    .weekday-selector {
      border: none;
      background-color: index.$textInputColor;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      border-radius: 1000px;
      cursor: pointer;
      font-size: 18px;
      &:hover {
        background-color: color.adjust(index.$textInputColor, $lightness: -10%);
      }
    }
    select {
      width: fit-content;
    }
    .hide-unselected, .hide-default {
      visibility: hidden;
    }
    &.selected {
      .weekday-selector {
        background-color: index.$lightAccentColor;
        &:hover {
          background-color: color.adjust(index.$lightAccentColor, $alpha: -0.3);
        }
      }
      .hide-unselected {
        visibility: visible;
        &.hide-default {
          visibility: hidden;
        }
      }
      &.custom .hide-default {
        visibility: visible;
      }
    }
  }
}

.schedule-form {
  form span, .holidays span {
    font-weight: bold;
    padding: 10px;
  }

  .extra-fields-container {
    flex-direction: row;
    flex-wrap: wrap;

    .extra-field {
      margin: 20px 20px 20px 0;
    }
  }

  .dependents-list .checkbox-wrapper {
    padding: 5px;
  }

  .submit-buttons {
    flex-direction: row;
    justify-content: flex-end;
    input {
      padding: 12px;
      margin-left: 10px;
      &.cancel {
        background-color: transparent;
        border: 1px index.$checkboxText solid !important;
        color: index.$checkboxText !important;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .holidays {
    button {
      padding: 10px;
      cursor: pointer;
      border-radius: 3px;
      background-color: index.$textInputColor;
      font-size: 20px;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: color.adjust(index.$textInputColor, $lightness: -10%);
      }
      &[type=submit] {
        margin-left: 20px;
        background-color: index.$accentColor;
        &:hover {
          background-color: index.$darkAccentColor;
        }
      }
    }

    .holiday-table {
      box-shadow: none;
      margin: 0;

      tr {
        background-color: transparent;
      }

      td {
        padding: 10px;

        &.date {
          flex: 1;
          text-align: left;
          align-items: flex-start;
        }
      }
    }

    .error {
      margin-left: 10px;
    }

    .inputs-container {
      flex-direction: row;
      margin: 10px 0;
      padding: 10px;

      input[type=text] {
        width: unset;
      }
    }
  }
}