@use "index";

body * {
  display: flex;
  flex-direction: column;
  font-family: "Josefin Sans", Arial, sans-serif;
  box-sizing: border-box;
  color: index.$primaryText;
}

script {
  display: none;
}

#App {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  & > div {
    flex: 1;
  }
}

.container {
  flex: 1;
}

button {
  border: none;
  outline: none;
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.loader {
  transition: all 0.2s ease;
  position: fixed;
  bottom: 20px;
  right: 20px;
  flex-direction: row;
  background-color: index.$loaderBackground;
  color: index.$textOnBackground;
  padding: 20px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 3px;
  box-shadow: 0 0 5px 0 #000000ff;
  .fas {
    color: index.$textOnBackground;
    margin-left: 15px;
    animation: spin 1s linear infinite;
  }
  &.hidden {
    transform: scale(0.9);
    opacity: 0;
    pointer-events: none;
  }
}

.styled-button {
  border-style: none;
  font-size: 16px;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px;
  background-color: index.$accentColor;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: index.$darkAccentColor;
  }
  .fas {
    margin-left: 10px;
  }
}

.error {
  transition: all 0.2s ease;
  color: index.$errorText;
  margin: 0;
  &.hidden {
    opacity: 0;
  }
}