@use "index";
@use "Table";

.setting-form {
  box-shadow: 0 0 10px 3px #0000002f;
  margin: 10px 0;
  padding: 30px;
  background-color: index.$backgroundColor;
  &, & > form {
    border-radius: Table.$tableRadius;
  }
  form {
    padding: 0;
    margin-top: 25px;
  }
  h3 {
    margin: 0;
    font-size: 20px;
  }
}

.single-row-setting {
  border-radius: Table.$tableRadius;
  box-shadow: 0 0 10px 3px #0000002f;
  margin: 10px 0;
  background-color: index.$backgroundColor;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 30px;
  h3 {
    align-self: center;
    font-size: 20px;
  }
  .title {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
  }
}

.cutoff-time {
  padding: 30px 30px 10px 30px;
  .title {
    align-self: flex-start;
  }
  .form-wrapper {
    form {
      flex-direction: row;
      align-items: stretch;
      & > * {
        margin: 0 5px;
      }
      label > span {
        display: none;
      }
      p {
        justify-content: center;
        font-weight: bold;
        font-size: 24px;
      }
      select {
        padding-right: 40px;
      }
    }
    .error {
      margin-top: 10px;
      text-align: right;
    }
  }
}

.hamburger-container {
  flex-direction: row;
  align-items: center;

  .checkbox-container {
    margin-right: 30px;
    align-items: center;
    flex-direction: row;
    font-weight: normal;
    font-size: 18px;

    p {
      margin-right: 10px;
    }
  }
}

.order-options-form {
  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .button {
      flex: 1;
      flex-direction: row;
      &:nth-of-type(2) {
        justify-content: flex-start;
      }
      &:nth-of-type(3) {
        justify-content: flex-end;
      }
    }
  }
  .simple-form, .warning-modal {
    padding: 60px;
  }
}