@use "index";

$contentContainerPadding: 20px;

#Home {
  display: flex;
  flex-direction: row;
}

header {
  background-color: index.$backgroundColor;
  padding: 20px;
  box-shadow: 0 3px 5px 0 #0000002f;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  & > div {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    img {
      height: 50px;
      margin-right: 15px;
    }
  }
  h3 {
    margin: 0;
    font-size: 24px;
    justify-content: flex-end;
  }
  .picker {
    flex: unset;
    margin-right: 30px;
  }
  .domain-picker {
    padding: 15px;
    font-size: 1em;
  }
}

nav {
  background-color: index.$backgroundColor;
  padding: 10px 0 0 10px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 300px;
    li {
      display: flex;
      align-items: stretch;
      padding: 0;
      margin: 0;
      position: relative;
      overflow: visible;
      .nav-link-accent {
        transition: all 0.5s ease;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: index.$lightAccentColor;
        border-right: 10px solid index.$accentColor;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
      }
      .nav-link {
        padding: 20px;
        flex: 1;
        text-decoration: none;
        font-weight: bold;
        font-size: 20px;
        z-index: 1000;
      }
    }
  }
}

.content-container {
  background-color: index.$scrollViewBackground;
  overflow: auto;
  height: 100px;
  flex: 1;
  padding: #{$contentContainerPadding - 10px} $contentContainerPadding;
  align-items: stretch;
  flex-direction: column;
}