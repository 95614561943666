@use "index";

.hamburger-button {
  outline: none;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  top: 0;
  &:hover {
    opacity: 0.6;
  }
}

.hamburger-menu {
  transition: all 0.2s ease;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  padding: 5px 0;
  background-color: index.$backgroundColor;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #0000001f;
  pointer-events: auto;
  button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 300;
    white-space: nowrap;
    background-color: index.$backgroundColor;
    cursor: pointer;
    &:hover {
      background-color: index.$scrollViewBackground;
    }
  }
  &.closed {
    transform: scale(0.9);
    opacity: 0;
    pointer-events: none;
  }
}