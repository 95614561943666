@use "index";

.warning-modal {
  background-color: index.$backgroundColor;
  padding: 30px;
  max-width: 350px;
  text-align: center;
  h3 {
    margin: 0;
  }
  div {
    flex-direction: row;
    justify-content: center;
    input {
      margin: 0 5px;
      &[type=button] {
        background-color: transparent;
        border: 1px index.$secondaryText solid;
        color: index.$secondaryText;
        &:hover {
          background-color: index.$textInputColor;
        }
      }
    }
  }
}