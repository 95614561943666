@use "index";
@use "sass:color";
@use "Home";

$tableRadius: 5px;

table {
  border-collapse: collapse;
  border-radius: $tableRadius;
  box-shadow: 0 0 10px 3px #0000002f;
  margin: 10px 0;
  background-color: index.$backgroundColor;
  tbody tr {
    background-color: index.$backgroundColor;
    flex-direction: row;
    &:nth-child(even) {
      background-color: index.$scrollViewBackground;
    }
    &:last-child {
      border-radius: 0 0 $tableRadius $tableRadius;
    }
    &.no-data {
      justify-content: center;
      text-align: center;
    }
    &.invisible {
      opacity: 0;
    }
    &.clickable {
      cursor: pointer;
      &:hover:nth-child(even) {
        background-color: color.adjust(index.$scrollViewBackground, $lightness: 2%);
      }
      &:hover:nth-child(odd) {
        background-color: color.adjust(index.$backgroundColor, $lightness: -2%);
      }
    }
  }
  .selection {
    margin: 20px;
    padding: 0;
  }
  thead {
    position: sticky;
    z-index: 10;
    top: -(Home.$contentContainerPadding) / 2;
    background-color: index.$backgroundColor;
    border-top-left-radius: $tableRadius;
    border-top-right-radius: $tableRadius;
    tr {
      box-shadow: 0 3px 5px 0 #0000001f;
      &:first-child {
        box-shadow: none;
      }
      flex-direction: row;
      th {
        background-color: index.$backgroundColor;
        border-radius: $tableRadius;
        font-size: 20px;
        flex-direction: row;
        justify-content: center;
        &:first-child {
          border-top-left-radius: $tableRadius;
        }
        &:last-child {
          border-top-right-radius: $tableRadius
        }
        &.table-title {
          flex: 3;
          text-align: center;
        }
        .sort-arrow {
          i {
            transition: all 0.2s ease;
          }
          &.down i {
            transform: rotate(180deg);
          }
        }
        .sort-arrow-placeholder {
          opacity: 0;
        }
        .sort-arrow, .sort-arrow-placeholder {
          margin: 0 20px;
        }
      }
      .menu-button {
        margin: 20px;
        padding: 0;
        background-color: transparent;
        flex: 1;
        align-self: center;
        position: relative;
        & > div {
          position: relative;
          display: block;
        }
        &.left {
          justify-content: start;
        }
        &.right {
          justify-content: flex-end;
          & > div > * {
            float: right;
          }
        }
      }
      .search-bar {
        flex-direction: row;
        align-items: center;
        align-self: center;
        display: flex !important;
        background-color: index.$textInputColor;
        padding-left: 15px;
        border-radius: 5px;
        .fas {
          margin-right: 10px;
          font-size: 24px;
        }
        input {
          outline: none;
          border: none;
          padding: 15px;
          width: 100%;
          background-color: transparent;
          font-size: 16px;
          &::placeholder {
            color: index.$textInputText;
          }
        }
      }
    }
  }
  tfoot {
    position: sticky;
    bottom: -10px;
    background-color: index.$backgroundColor;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 -3px 5px 0 #0000001f;
    .page-buttons {
      flex-direction: row;
      justify-content: flex-end;
      font-size: 16px;
      button {
        flex-direction: row;
        padding: 10px;
        margin: 0 2px;
        cursor: pointer;
        background-color: index.$textInputColor;
        &:hover {
          background-color: color.adjust(index.$textInputColor, $lightness: -10%);
        }
        &:disabled, &[disabled] {
          cursor: default;
          background-color: transparent;
          i {
            color: index.$secondaryText
          }
        }
      }
      label {
        flex-direction: row;
        padding: 5px 10px;
        align-items: center;
        input {
          padding: 10px;
        }
      }
    }
  }
  td, th {
    padding: 20px;
    border: none;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  td.large {
    text-align: left;
    align-items: start;
  }
  p {
    margin: 0;
    padding: 0;
  }
}